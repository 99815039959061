import { Navigate } from 'react-router-dom'


const PrivateRoute = ({ children }) => {
    const auth = localStorage.getItem('isLogged');
    return (
      auth ? children : <Navigate to="login"/>
    )
}

export default PrivateRoute;