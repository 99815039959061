import styles from './Header.module.scss';
import {Layout} from 'antd';
import { Link } from 'react-router-dom';
import {
    LogoutOutlined
} from '@ant-design/icons';





export const Header = () => {

    return (
        <Layout className={styles.header}>
           
             
        </Layout>
    )}