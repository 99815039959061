import { Routes, Route, Outlet } from 'react-router-dom';
import './App.css';
import { Layout } from 'antd';
import { SiteLayout } from './components/SiteLayout';
import { NewProduct } from './components/newProduct/NewProduct';
import { Products } from './components/products/Products';
import { Auth } from './components/auth/Auth';
import PrivateRoute from './axios/PrivateRoute';

function App() {
  return (
    <div className="App">
          <Routes>
            <Route path="/" element={<PrivateRoute ><SiteLayout><Outlet /></SiteLayout></PrivateRoute>}>
              <Route path="new" element={<NewProduct />}/>
              <Route path="/" element={<Products/>}/>
            </Route>  
            <Route path="login" element={<Auth />}/>    
          </Routes>
    </div>
  );
}

export default App;
