import { instance } from './index';

export function getProducts () {

    return instance.get('/products/all');   
  };



  export function addProduct (values) {
    try {

        return instance.post('products/new', values);  
       
        // Здесь вы можете обработать успешный ответ от сервера
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        // Здесь вы можете обработать ошибку
      }
    
  };

  export function auth (values) {
    try {
        return instance.post('/auth/login', values);  
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
      }
    
  };

  
  export function editProduct (values) {
    try {      
        console.log(values)
        return instance.put('products/edit', values)
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
      }
    
  };

  export function deleleProduct (id) {

    return instance.delete(`/products/delete/${id}`);   
  };



  export function sendtoViber (values) {
    try {

        return instance.post('products/sendviber', values);  
       
        // Здесь вы можете обработать успешный ответ от сервера
      } catch (error) {
        console.error('Ошибка при отправке данных в Viber:', error);
        // Здесь вы можете обработать ошибку
      }
    
  };



