import {
    ScheduleOutlined,
    FileDoneOutlined,
    AppstoreAddOutlined,
    BulbOutlined,
    HomeOutlined,
    VideoCameraOutlined,
    AppstoreOutlined,
    TeamOutlined
  } from '@ant-design/icons';
import React from 'react';
import styles from './Navigation.module.scss';
import { Layout, Menu, theme } from 'antd';
import {BrowserRouter as Router,Link,Route,Routes} from 'react-router-dom';


export const Navigation = () => {
        const links = [
            '/',
            '/new',
            
        ];
        
        const labels = [
        'Все товары',
        'Добавить товар', 
    ]
        const items = links.map((link, index) => ({
            key: String(index + 1),

            label: (<Link to={link}>{labels[index]}</Link>),
        
        }))
        return(
            <Menu mode="inline" items={items} className={styles.nav}/>
        )
    
    
}