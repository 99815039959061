/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Upload,
  Button,
  Space,
  Select,
  Checkbox,
  Image,
} from "antd";
import { editProduct, getProducts, deleleProduct } from "../../axios/api";
import {
  InboxOutlined,
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import styles from "./Products.module.scss";
const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}

export const Products = () => {
  const [products, setProducts] = useState();
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const [file, setFile] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const getData = () => {
    getProducts({}).then((data) => {
      let temp = data.data;
      const sizesWithAdditionalFields = temp.flatMap((product) =>
        product.ProductSizes.map((size) => ({
          ...size,
          productName: product.name,
          manufacturer: product.manufacturer,
          material: product.material,
          rate: product.rate,
          image: product.image,
          key: size.id,
        }))
      );
      console.log(sizesWithAdditionalFields);
      setProducts(sizesWithAdditionalFields);
    });
  };
  useEffect(() => {
    getData();
  }, []);

  if (!products) {
    return <div>Loading...</div>;
  }
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const deleteData = (data) => {
    deleleProduct(data).then((data) => {
      getData();
    });
  };
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      title === "Рейтинг" ||
      title === "Цена" ||
      title === "Скидка" ||
      title === "Остаток" ? (
        <InputNumber />
      ) : title === "Топ" || title === "Доступно" ? (
        <Checkbox />
      ) : title === "Картинка" ? (
        <Form.Item name="image">
          <Upload
            name="image"
            listType="picture"
            beforeUpload={(file) => {
              setFile(file);
              console.log(file);
              return false; // Отменить автоматическую загрузку файла
            }}
          >
            <span>{file ? file.name : ""}</span>
            <Button icon={<UploadOutlined />}>Добавить</Button>
          </Upload>
        </Form.Item>
      ) : title === "Размер" ? (
        <Select placeholder="Выберите размер">
          <Option value="1,5 сп">1,5 сп</Option>
          <Option value="2-сп">2-сп</Option>
          <Option value="Евро">Евро</Option>
          <Option value="Детские">Детские</Option>
          <Option value="Семейные">Семейные</Option>
          <Option value="Полотенца">Полотенца</Option>
          <Option value="Подушки">Подушки</Option>
          <Option value="Одеяла">Одеяла</Option>
          <Option value="Пледы">Пледы</Option>
          <Option value="Простыни">Простыни</Option>
          <Option value="Наматрасники">Наматрасники</Option>
        </Select>
      ) : (
        <Input />
      );
    return (
      <td {...restProps}>
        {editing &&
        title !== "Топ" &&
        title !== "Доступно" &&
        title !== "Цвет" ? (
          <Form.Item
            name={dataIndex}
            //  valuePropName="checked"
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Введите ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : editing && (title === "Топ" || title === "Доступно") ? (
          <Form.Item
            name={dataIndex}
            valuePropName="checked"
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Введите ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : editing && title === "Цвет" ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: false,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setFile(null);
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const formData = new FormData();
      console.log(file);
      if (file) {
        formData.append("image", file);
      }
      formData.append("id", row.id);
      formData.append("name", row.productName);
      formData.append("material", row.material);
      formData.append("rate", row.rate);
      formData.append("manufacturer", row.manufacturer);
      formData.append("size", row.size);
      formData.append("price", row.price);
      formData.append("sale", row.sale);
      formData.append("quantity", row.quantity);
      formData.append("description", row.description);
      formData.append("available", row.available);
      formData.append("top", row.top);
      formData.append("color", row.color);

      editProduct(formData).then((data) => {
        setFile(null);
        getData();
      });
      const newData = [...products];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setProducts(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setProducts(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`поиск`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Искать
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Отменить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "2%",
      editable: true,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Название",
      dataIndex: "productName",
      width: "10%",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.productName.length - b.productName.length,
      ...getColumnSearchProps("productName"),
    },
    {
      title: "Производство",
      dataIndex: "manufacturer",
      width: "10%",
      editable: true,
      sorter: (a, b) => a.manufacturer.length - b.manufacturer.length,
    },
    {
      title: "Материал",
      dataIndex: "material",
      width: "10%",
      editable: true,
      sorter: (a, b) => a.material.length - b.material.length,
    },
    {
      title: "Размер",
      dataIndex: "size",
      width: "10%",
      editable: true,
      filters: [
        {
          text: "1,5 сп",
          value: "1,5 сп",
        },
        {
          text: "2-сп",
          value: "2-сп",
        },
        {
          text: "Евро",
          value: "Евро",
        },
        {
          text: "Детские",
          value: "Детские",
        },
        {
          text: "Семейные",
          value: "Семейные",
        },
        {
          text: "Полотенца",
          value: "Полотенца",
        },
        {
          text: "Подушки",
          value: "Подушки",
        },
        {
          text: "Одеяла",
          value: "Одеяла",
        },
        {
          text: "Пледы",
          value: "Пледы",
        },
        {
          text: "Простыни",
          value: "Простыни",
        },
        {
          text: "Наматрасники",
          value: "Наматрасники",
        },
      ],
      onFilter: (value, record) => record.size.indexOf(value) === 0,
    },
    {
      title: "Рейтинг",
      dataIndex: "rate",
      width: "10%",
      editable: true,
      sorter: (a, b) => a.rate - b.rate,
    },
    {
      title: "Картинка",
      dataIndex: "image",
      width: "10%",
      editable: true,
      render: (imageURL) => (
        <Image src={`https://adminshop.online/${imageURL}`} />
      ),
    },
    {
      title: "Описание",
      dataIndex: "description",
      width: "10%",
      editable: true,
    },
    {
      title: "Цвет",
      dataIndex: "color",
      width: "10%",
      editable: true,
    },
    {
      title: "Цена",
      dataIndex: "price",
      width: "10%",
      editable: true,
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Скидка",
      dataIndex: "sale",
      width: "10%",
      editable: true,
      sorter: (a, b) => a.sale - b.sale,
    },
    {
      title: "Остаток",
      dataIndex: "quantity",
      width: "10%",
      editable: true,
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Доступно",
      dataIndex: "available",
      width: "10%",
      editable: true,
      sorter: (a, b) => a.available - b.available,
      render: (val) => (val === true ? "Да" : "Нет"),
    },
    {
      title: "Топ",
      dataIndex: "top",
      width: "10%",
      editable: true,
      render: (val) => (val === true ? "Да" : "Нет"),
      sorter: (a, b) => a.top - b.top,
    },
    {
      title: "Изменить",
      width: "2%",
      dataIndex: "edit",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className={styles.btns}>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Ок
            </Typography.Link>
            <Popconfirm title="Отменить?" onConfirm={cancel}>
              <a>Отменить</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            <EditOutlined />
          </Typography.Link>
        );
      },
    },
    {
      title: "Удалить",
      width: "2%",
      dataIndex: "delete",
      render: (_, record) =>
        products.length >= 1 ? (
          <Popconfirm
            title="Удалить товар?"
            onConfirm={() => deleteData(record.key)}
          >
            <DeleteOutlined />
          </Popconfirm>
        ) : null,
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        className={styles.table}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={products}
        columns={mergedColumns}
        rowClassName="editable-row"
      />
    </Form>
  );
};
