import React, { useState } from "react";
import {
  InboxOutlined,
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Input, Modal } from "antd";
import { useNavigate } from "react-router-dom";

import styles from "./NewProduct.module.scss";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Form,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select,
  Slider,
  Space,
  Switch,
  Upload,
  Image,
  Spin,
} from "antd";
import { addProduct } from "../../axios/api";

const { Option } = Select;

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const NewProduct = () => {
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = (values) => {
    setLoading(true);
    console.log("Success:", values);
    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", values.name);
    formData.append("material", values.material);
    formData.append("rate", values.rate);
    formData.append("manufacturer", values.manufacturer);
    formData.append("sizes", JSON.stringify(values.sizes));
    //    values.image = file;
    addProduct(formData).then((data) => {
      form.resetFields();
      setLoading(false);
      showModal();
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    navigate("/new");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  return (
    <div className={styles.wrapper}>
      <h2>Добавить товар</h2>

      <Form
        form={form}
        className={styles.form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          className={styles.input}
          label="Название"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className={styles.input}
          label="Материал"
          name="material"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className={styles.input}
          label="Производство"
          name="manufacturer"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className={styles.input}
          name="image"
          label="Изображение"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="image"
            listType="picture"
            beforeUpload={(file) => {
              setFile(file);
              return false; // Отменить автоматическую загрузку файла
            }}
          >
            <Button icon={<UploadOutlined />}>Добавить</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          className={styles.input}
          name="rate"
          label="Рейтинг"
          rules={[
            {
              required: true,
              type: "number",
              min: 0,
              max: 5,
            },
          ]}
        >
          <Rate />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Form.List name="sizes">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    className={styles.sizes}
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      className={styles.input}
                      label="Размер"
                      name={[name, "size"]}
                      hasFeedback
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Выберите размер">
                        <Option value="1,5 сп">1,5 сп</Option>
                        <Option value="2-сп">2-сп</Option>
                        <Option value="Евро">Евро</Option>
                        <Option value="Детские">Детские</Option>
                        <Option value="Семейные">Семейные</Option>
                        <Option value="Полотенца">Полотенца</Option>
                        <Option value="Подушки">Подушки</Option>
                        <Option value="Одеяла">Одеяла</Option>
                        <Option value="Пледы">Пледы</Option>
                        <Option value="Простыни">Простыни</Option>
                        <Option value="Наматрасники">Наматрасники</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      className={styles.input}
                      label="Описание"
                      name={[name, "description"]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      className={styles.input}
                      label="Цвет"
                      name={[name, "color"]}
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      className={styles.input}
                      label="Цена со скидкой"
                      name={[name, "sale"]}
                      rules={[
                        {
                          required: true,
                          type: "number",
                          min: 0,
                          max: 1000,
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      className={styles.input}
                      label="Цена"
                      name={[name, "price"]}
                      rules={[
                        {
                          required: true,
                          type: "number",
                          min: 0,
                          max: 10000,
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      className={styles.input}
                      label="Остаток"
                      name={[name, "quantity"]}
                      rules={[
                        {
                          required: true,
                          type: "number",
                          min: 0,
                          max: 10000,
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>

                    <Form.Item
                      valuePropName="checked"
                      name={[name, "available"]}
                    >
                      <Checkbox>Доступно</Checkbox>
                    </Form.Item>

                    <Form.Item valuePropName="checked" name={[name, "top"]}>
                      <Checkbox>Топ</Checkbox>
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить размер
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Button type="primary" htmlType="submit">
            Добавить
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="Новый товар"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Нет"
        okText="Да"
      >
        <p>Товар добавлен, хотите добавить еще?</p>
      </Modal>

      {loading && (
        <div className="loading-overlay">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </div>
  );
};
