/* eslint-disable */ 
import { Button, Checkbox, Form, Input } from 'antd';
import styles from './Auth.module.scss';
import { instance } from '../../axios/index';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { auth } from '../../axios/api';
import React, { useState } from 'react';


export const Auth = () => {
  //const [userState, setUserState] = useAtom(user);
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const onFinish = async (values) => {
    console.log('Success:', values);
    const userData = {
      username: values.username, password: values.password
    }
    auth(userData).then(data => {
        console.log(data.data)
        localStorage.setItem('user', data.data.token);
        setUser(data.data)

    }).then(d => {
       
    localStorage.setItem('isLogged', true);
     navigate('/');
    });
    //const user = await instance.post('auth/login', userData);
  
    


  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
    return (
        <div className={styles.loginContainer}>
            <h2>Авторизация</h2>
 <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
    className={styles.loginForm}
  >
    <Form.Item
     className={styles.box}
      label="Логин"
      name="username"
      rules={[
        {
          required: true,
          message: 'Введите логин',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
    className={styles.box}
      label="Пароль"
      name="password"
      rules={[
        {
          required: true,
          message: 'Введите пароль',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>


    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Войти
      </Button>
    </Form.Item>
  </Form>
        </div>
       
    )
}

