/* eslint-disable */

import { Layout, Image, Button, Form, Input, Select, Spin } from "antd";
import styles from "./Sidebar.module.scss";
import * as XLSX from "xlsx";
const { Sider } = Layout;
import { useEffect, useState } from "react";
import { Navigation } from "../navigation/Navigation";
import { getProducts, sendtoViber } from "../../axios/api";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { instance } from "../../axios";

export const Sidebar = () => {
  const [data, setData] = useState();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    setLoading(true);
    instance
      .post("products/sendviber", values, { responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "images.zip";
        a.click();
        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Ошибка при скачивании архива:", error);
      });
  };

  const exportToExcel = () => {
    getProducts({}).then((products) => {
      console.log(products);
      const worksheet = XLSX.utils.json_to_sheet(products.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Товары"); // Замените 'Товары' на имя листа, как вам нужно

      // Создание и загрузка файла Excel
      XLSX.writeFile(workbook, "товары.xlsx");
    });
  };

  const exit = () => {
    localStorage.removeItem("isLogged");
    localStorage.removeItem("user");
    navigate("/login");
  };
  return (
    <Sider className={styles.sidebar} width={250}>
      <Navigation />
      <Button type="primary" onClick={exportToExcel} className={styles.button}>
        <DownloadOutlined />
        Экспорт товаров
      </Button>
      <div className={styles.viberSend}>
        <h4>Получить варианты</h4>
        <Form
          form={form}
          name="Отправить варианты Viber"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
          scrollToFirstError
        >
          <Form.Item name="size" hasFeedback rules={[{ required: true }]}>
            <Select placeholder="Выберите размер">
              <Option value="1,5 сп">1,5 сп</Option>
              <Option value="2-сп">2-сп</Option>
              <Option value="Евро">Евро</Option>
              <Option value="Детские">Детские</Option>
              <Option value="Семейные">Семейные</Option>
              <Option value="Полотенца">Полотенца</Option>
              <Option value="Подушки">Подушки</Option>
              <Option value="Одеяла">Одеяла</Option>
              <Option value="Пледы">Пледы</Option>
              <Option value="Простыни">Простыни</Option>
              <Option value="Наматрасники">Наматрасники</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Скачать
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Button type="primary" onClick={exit} className={styles.buttonExit}>
        Выйти
      </Button>
      {loading && (
        <div className="loading-overlay">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </Sider>
  );
};
