import React from "react";

import { Sidebar } from './sidebar/Sidebar';
import { Header } from './header/Header';
import { Layout } from "antd";

const { Content } = Layout;

export const SiteLayout = ({ children }) => {
    return (
        <Layout>      
            <Sidebar />            
            <Layout>
                <Header />
                <Content className='content'>
                    {children}
                </Content>
            </Layout>
      </Layout>
    )
}
