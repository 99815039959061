import axios from "axios";

export const instance = axios.create({
  baseURL: "https://adminshop.online/",
  timeout: 90000,
  headers: {
    "X-Custom-Header": "foobar",
    "Content-Type": "multipart/form-data",
  },
});
